<template>
  <div class="main-content white">
    <div class="container">
      <ul class="group-manage-menu">
        <li>
          <router-link
            :to="{
              name: 'CreatedGroupManage',
              params: {
                group_id: groupId
              }
            }"
          >
            成團通知
          </router-link>
        </li>
        <li>
          <router-link
            :to="{
              name: 'CreatedGroupCancel',
              params: {
                group_id: groupId
              }
            }"
          >
            流團通知
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    groupId() {
      return this.$route.params.group_id;
    }
  }
};
</script>
