var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content white"},[_c('div',{staticClass:"container"},[_c('ul',{staticClass:"group-manage-menu"},[_c('li',[_c('router-link',{attrs:{"to":{
            name: 'CreatedGroupManage',
            params: {
              group_id: _vm.groupId
            }
          }}},[_vm._v(" 成團通知 ")])],1),_c('li',[_c('router-link',{attrs:{"to":{
            name: 'CreatedGroupCancel',
            params: {
              group_id: _vm.groupId
            }
          }}},[_vm._v(" 流團通知 ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }